import { TodoList as List } from '../schemas/todoList';

interface TodoListProps {
    list: List;
}

const TodoList = ({ list }: TodoListProps) => {
    const listItems = list.items.map((item, index) => (
        <li key={index}>{item.name}</li>
    ));

    return (
        <>
            <h2>{list.name}</h2>
            {listItems}
        </>
    );
};

export default TodoList;
