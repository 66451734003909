import logo from './logo.svg';
import './App.css';
import { TodoList as ListInterface } from './schemas/todoList';
import TodoList from './components/todoList.tsx';

export const testList: ListInterface = {
    id: 'testListId',
    name: 'Test List',
    items: [
        {
            name: 'Do first thing',
            isCompleted: false,
        },
        {
            name: 'Do second thing',
            isCompleted: false,
        },
    ],
};

function App() {
    return (
        <div className="App">
            {/* <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Edit <code>src/App.js</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header> */}
            <header>Liz's Todos</header>
            <TodoList list={testList} />
        </div>
    );
}

export default App;
